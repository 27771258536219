import { BACK_ORDER_PROGRESS } from "@/pages/workspace/backgroundCheck/order/config";
import { lineRc, orderBasicData } from "@/pages/home/order/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import Vue from "vue";
import { getFakeId } from "@/utils/tools";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    // 默认易查记录 1=全部候选人 2=到场记录 3=易查记录 4=委托记录
    candidateTab: 4,
    // 候选人管理过滤条件
    candidateTabFilters: [
      // {
      //   key: 1,
      //   filters: {
      //     candidateName: "",
      //     candidatePhone: "",
      //     taskStateId: undefined,
      //     reportLevel: undefined,
      //     cssTaskProgressId: undefined
      //   },
      //   page: {
      //     current: 1,
      //     pageSize: 10,
      //     total: 0
      //   }
      // },
      // {
      //   key: 2,
      //   filters: {
      //     candidateName: "",
      //     registrationTime: [],
      //     mobile: ""
      //   },
      //   page: {
      //     current: 1,
      //     pageSize: 10,
      //     total: 0
      //   }
      // },
      // {
      //   key: 3,
      //   filters: {
      //     candidateName: "",
      //     consignTime: []
      //   },
      //   page: {
      //     current: 1,
      //     pageSize: 10,
      //     total: 0
      //   }
      // },
      {
        key: 4,
        filters: {
          // 候选人姓名
          candidateName: "",
          // 报告编号
          taskNumber: "",
          // 委托HRID
          targetUserId: undefined,
          isShowUnRead: undefined,
          // 报告级别
          reportLevelId: undefined,
          // 委托时间
          consignTime: [],
          // 信息确认时间
          confirmTime: [],
          // 任务进度
          taskProgressId: undefined,
          // 报告版本
          templateName: undefined
        },
        page: {
          current: 1,
          pageSize: 10,
          total: 0
        }
      }
    ],
    // 新建委托候选人列表
    orderCandidateList: [],
    // 易查宝候选人列表
    echeckCandidateList: [],
    // 新建订单候选人列表
    candidateList: [],
    // 选择的模板
    selectedTemplate: {},
    // 新建订单进度
    progressActive: BACK_ORDER_PROGRESS[0].key,
    // 新建订单模板
    templateList: [],
    // 新建订单抄送邮箱
    ccEmailSelectedList: [],
    // 新建订单所选客户（多机构）
    companyId: undefined,
    // 性别字典
    genderList: [],
    // 默认抄送邮箱
    ccEmailList: [],
    // 业务线配置
    lineConfig: {
      // 是否启用候选人扫码下单
      enableCandidateQROrder: true,
      // 是否允许下单
      newOrderEnabled: true,
      // 是否启用易查宝订单上传附件功能
      quickOrderAttachmentSupport: true,
      // 是否启用易查宝功能
      quickOrderControl: true,
    },
    // 表单配置
    formConfig: {
      // 易查宝订单-联系候选人-是否默认选中
      contactCandidateForQuickOrder: false,
      // 工作订单-联系候选人-是否默认选中
      contactCandidateForWorkOrder: false,
      // 易查宝订单-线上授权-是否默认选中
      onlineAuthForQuickOrder: false,
      // 工作订单-线上授权-是否默认选中
      onlineAuthForWorkOrder: false,
    },
    // 服务器时间
    serverTime: moment(),
    // 默认预计入职时间天数
    defaultExpectedEntryTimeAfterDays: 7,
  },
  mutations: {
    setCandidateTab(state, action) {
      state.candidateTab = action;
    },
    setCandidateTabFilters(state, action) {
      const { index, filters, pageCurrent, pageSize, pageTotal } = action;
      if (!state.candidateTabFilters[index]) {
        return;
      }
      if (filters === false) {
        state.candidateTabFilters[index].filters = {};
      } else {
        if (filters) {
          for (const k in filters) {
            state.candidateTabFilters[index].filters[k] = filters[k];
          }
        }
      }
      if (typeof pageCurrent === "number") {
        state.candidateTabFilters[index].page.current = pageCurrent;
      }
      if (typeof pageSize === "number") {
        state.candidateTabFilters[index].page.pageSize = pageSize;
      }
      if (typeof pageTotal === "number") {
        state.candidateTabFilters[index].page.total = pageTotal;
      }
    },
    setOrderCandidateList(state, action) {
      state.orderCandidateList = action;
    },
    setEcheckCandidateList(state, action) {
      state.echeckCandidateList = action;
    },
    setSelectedTemplate(state, action) {
      state.selectedTemplate = action;
    },
    setProgressActive(state, action) {
      state.progressActive = action;
    },
    setCcEmailSelectedList(state, action) {
      state.ccEmailSelectedList = action;
    },
    setCandidateList(state, action) {
      state.candidateList = action;
    },
    setCompanyId(state, action) {
      state.companyId = action;
    },
    setCcEmailList(state, action) {
      state.ccEmailList = action;
    }
  },
  getters: {},
  actions: {
    async getBasicData({ state }, params) {
      state.templateList = [];
      const res = await orderBasicData(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      // 模板
      res.data.template = res.data.template || [];
      res.data.template.forEach(it => {
        it.type = "0";
      });
      state.templateList = res.data.template;
      // 性别字典
      const genderList = res.data.gender || [];
      state.genderList = genderList.map(it => ({
        ...it,
        value: it.id
      }));
      state.ccEmailList = res.data.reportReceiveEmailCc.map(it => ({
        email: it,
        id: getFakeId()
      }));
      if (res.data.reportReceiveEmailCc.length) {
        state.ccEmailSelectedList = res.data.reportReceiveEmailCc;
      }
      // 当前服务器时间
      state.serverTime = res.data.serverTime ? moment(res.data.serverTime) : moment();
      // 默认预计入职时间天数
      state.defaultExpectedEntryTimeAfterDays = res.data.defaultExpectedEntryTimeAfterDays || 14;
      // ess表单配置
      state.formConfig.contactCandidateForQuickOrder = res.data.contactCandidateForQuickOrder;
      state.formConfig.contactCandidateForWorkOrder = res.data.contactCandidateForWorkOrder;
      state.formConfig.onlineAuthForQuickOrder = res.data.onlineAuthForQuickOrder;
      state.formConfig.onlineAuthForWorkOrder = res.data.onlineAuthForWorkOrder;
      // 业务线配置
      const lineRes = await lineRc();
      if (lineRes.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(lineRes);
        return;
      }
      state.lineConfig = lineRes.data;
      //#region 处理易查模板
      if (res.data.quickModule && lineRes.data.quickOrderControl) {
        const obj = {
          id: "_LOCAL_QUICK",
          name: Vue.prototype.$t("backCheck.echeckName"),
          type: "1",
          module: res.data.quickModule
        };
        res.data.template.unshift(obj);
      }
      state.templateList = res.data.template;
      //#endregion
    }
  }
};
